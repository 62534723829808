import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Cards from "../../UI/Cards";

const ServicesList = ({ lang }) => {
  const dataQuery = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "ServiceTemplate" } } }
        sort: { fields: [frontmatter___weight], order: ASC }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              cover {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 300, maxHeight: 200) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              language
            }
          }
        }
      }
    }
  `);
  const data = [];
  dataQuery.allMarkdownRemark.edges.map(value => {
    if (value.node.frontmatter.language == lang) {
      data.push({
        title: value.node.frontmatter.title,
        href: value.node.fields.slug,
        image: value.node.frontmatter.cover,
        language: value.node.frontmatter.language
      });
    }
  });
  return (
  <Cards data={data} lang={lang} />
  );
};

export default ServicesList;
