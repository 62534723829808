import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import { Box, Heading } from "rebass/styled-components";
import ServicesSlides from "./ServicesSlides";

const ServicesSlider = ({ data, lang }) => {
  return (
    <Wrapper data={data}>
      {data.title && (
        <Heading as="h2" variant="h2">
          {data.title}
        </Heading>
      )}
      <ServicesSlides lang={lang} />
    </Wrapper>
  );
};

ServicesSlider.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.any
  })
};
export default ServicesSlider;

export const query = graphql`
  fragment ServicesSliderBlock on MarkdownRemarkFrontmatterBlockscollection {
    title
  }
`;
