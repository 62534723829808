import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import Forms from "../../Forms";
import { Box } from "rebass";

const Form = ({ data, theme }) => {
  if (data.form === "" || data.form === null || !data.hasOwnProperty("form")) {
    return <></>;
  }
  const formValues = typeof data.form === "string" ? false : data.form.frontmatter;
  return (
    <Wrapper data={data}>
      <Forms data={formValues} />
    </Wrapper>
  );
};

Form.propTypes = {
  data: PropTypes.shape({
    form: PropTypes.shape({
      frontmatter: PropTypes.any
    })
  })
};
export default Form;

export const query = graphql`
  fragment FormBlock on MarkdownRemarkFrontmatterBlockscollection {
    form {
      frontmatter {
        title
        formId
        language
        successMessage
        elements {
          imputType
          label
          width
          name
          placeholder
          required
          type
          default
          multiple
          maxFiles
          maxSizeBytes
          options {
            value
            label
          }
          shakey
          vads_amount
          vads_payment_config
          vads_page_action
          vads_currency
          vads_ctx_mode
          vads_site_id
          vads_url_refused
          vads_url_return
          vads_url_success
          vads_url_cancel
          vads_url_error
          vads_url_check
          printLabel
        }
      }
    }
  }
`;
