import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Markdown from "../../UI/Markdown";
import CustomImage from "../../UI/CustomImage";
import Link from "../../UI/Link";
import Grid from "../../UI/Grid";
import { Box, Heading, Flex, Button } from "rebass/styled-components";
import loadable from "@loadable/component";
const Slider = loadable(() => import("../../UI/Slider"));

const ServicesSlides = ({lang}) => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "ServiceTemplate" } } }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              maincontent
              cover {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 600, maxHeight: 600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              language
            }
          }
        }
      }
    }
  `);
  const content = data.allMarkdownRemark.edges;
  let service = "";
  var settings = {
    dots: true,
    autoplaySpeed: 8000,
    arrows: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    // adaptiveHeight: true
  };
  const sx = {
    ".slick-slide": {
      "blockquote": {
        border: "none !important",
        m: "0 !important",
        p: "0 !important"
      },
      "&.slick-current": {
        zIndex: 10
      },
      "& > div > div": {
        display: "block !important"
      }
    },
    ".slick-arrow": {
      right: [0,0,0,"50%"],
      left: "inherit",
      top: "inherit",
      bottom: 0,
      mt: [6,6,6,0],
      width: "64px",
      height: "64px",
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "grayLight",
      zIndex: 1,
      transform: ["none","none","none","translateX(-15px)"],
      "&:before": {
        fontFamily: "fontello",
        fontSize: 13,
        color: "primary",
        opacity: "1"
      },
      "&.slick-prev": {
        mr: "63px",
        "&:before": {
          content: "'\\e80a'"
        }
      },
      "&.slick-next": {
        "&:before": {
          content: "'\\e80b'"
        }
      },
      "&:hover": {
        bg: "primary",
        borderColor: "primary",
        "&:before": {
          color: "white"
        }
      }
    },
    "ul.slick-dots": {
      mt: [6,6,6,0],
      position: ["inherit","inherit","inherit","absolute"],
      bottom: 0,
      left: "0",
      display: "flex !important",
      alignItems: "center",
      justifyContent: "flex-start",
      height: "64px",
      "li": {
          p: "0 !important",
          ml: 0,
          mr: 2,
          height: "auto",
          width: "auto",
          "&:before": {
            display: "none"
          },
          "button": {
            p: 0,
            width: "8px",
            height: "8px",
            bg: "gray",
            borderRadius: "4px",
            transition: "all 200ms linear",
            "&:before": {display: "none"}
          },
          "&.slick-active": {
            "button": {
              transform: "scale(1.5)",
              bg: "primary"
            }
          }
      }
    }
  };
  return (
    <Box pb={5,5,10} sx={sx}>
      <Slider {...settings}>
      {content.map((item, i) => (
        service = item.node.frontmatter,
        service.language == lang && (
          <Box key={i}>
            <Grid childWidth={[1,1,1/2]} flexDirection={["colum","column","row-reverse"]}>
              <CustomImage img={service.cover} />
              <Flex alignItems="center" height="100%" pt={[6,6,0]}>
                <Box>
                  <Heading as="h3" variant="h3" fontWeight="bold">{service.title}</Heading>
                  <Markdown>{service.maincontent}</Markdown>
                  <Link href={lang == "en" ? "/en"+item.node.fields.slug : item.node.fields.slug}>
                    <Button variant="linkText">En savoir plus</Button>
                  </Link>            
                </Box>
              </Flex>
            </Grid>
          </Box>
        )
      ))}
      </Slider>
    </Box>
  );
};

export default ServicesSlides;
