import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import Icofont from "../../UI/Icofont";
import { Box, Heading, Text, Button } from "rebass/styled-components";
import Link from "../../UI/Link";

const Header = ({ data }) => {
  const fullHeightActived = data.blockSettings ? data.blockSettings.fullHeight : false;
  const bgImg = data.blockSettings
    ? data.blockSettings.bgImg
      ? data.blockSettings.bgImg
      : ""
    : "" || "";
  data.ctas = data.ctas || [];

  const bgColor = data.blockSettings
    ? data.blockSettings.bg
      ? data.blockSettings.bg
      : false
    : false || false;
  const sx = {
    backgroundImage: `url(${typeof bgImg === "string" ? bgImg : bgImg.childImageSharp.fluid.src})`,
    position: "relative",
    ">*": {
      position: "relative",
      zIndex: 1
    }
  };
  if (bgColor) {
    sx[":before"] = {
      content: '""',
      position: "absolute",
      zIndex: 0,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      width: "100%",
      height: "100%",
      backgroundImage: "radial-gradient(circle, " + bgColor + "99 0%, " + bgColor + "99 100%)"
    };
  }
  const wrapperProps = {};
  if (fullHeightActived) {
    if (data.blockSettings.padding) {
      containerProps.pb = data.blockSettings.padding.bottom;
      containerProps.pt = data.blockSettings.padding.top;
    }
    wrapperProps.pb = 0;
    wrapperProps.pt = 0;
  }
  return (
    <Box pt={["5px","10px","10px"]}>
      <Wrapper
        data={data}
        sx={sx}
        py={[8,10,19]}
      >
        <Box display="flex" {...wrapperProps}>
          <Box
          textAlign={["center","left"]}
          bg={["transparent","rgba(255,555,255,.9)"]}
          py={[12,12,13]}
          px={6}
          maxWidth={["100%","50vw","70%","65%","50%"]}
          ml={[0,"-30px",0,0]}
          color={["#fff","#2a2a2a","#2a2a2a"]}
          sx={{
            position: "relative",
            "&:before": {
              content: "''",
              display: "block",
              height: "100%",
              width: [0,0,"5px"],
              bg: "primary",
              position: "absolute",
              left: [0,0,"-5px"],
              top: 0
            }
          }}>
            <Heading as="h1" variant="h1">{data.bigTitle}</Heading>
            {data.subTitle && (
              <Text color="white">{data.subTitle}</Text>
            )}
            {data.ctas.map((cta, i) => (
              <Link href={cta.href} key={i}>
                <Button>
                  <Icofont icon={cta.icon} />
                  {cta.label}
                </Button>
              </Link>
            ))}
          </Box>
        </Box>
      </Wrapper>
    </Box>
  );
};

Header.propTypes = {
  data: PropTypes.shape({
    bigTitle: PropTypes.any,
    blockSettings: PropTypes.shape({
      bg: PropTypes.any,
      bgImg: PropTypes.any
    }),
    ctas: PropTypes.array,
    subTitle: PropTypes.any
  })
};

export default Header;
export const query = graphql`
  fragment HeaderBlock on MarkdownRemarkFrontmatterBlockscollection {
    content
    bigTitle
    subTitle
    ctas {
      href
      icon
      label
    }
  }
`;
