import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import Markdown from "../../UI/Markdown";
import Fontello from "../../UI/Fontello";
import { Box, Heading, Flex, Text } from "rebass/styled-components";
import loadable from "@loadable/component";

const Map = loadable(() => import("./Map"));

const InfosMap = ({ data }) => {
  var telLink = "tel:";
  var mailTo = "mailto:";
  return (
    <Wrapper data={data}>
        <Flex variant="grid" alignItems="center">
          {data.showInfo && (
            <Box
            variant="gridItem"
            width={[1, 1, 2/5]}
            pb={[3,3,0]}
            sx={{
              "p": {
                m:0
              },
              "i": {
                color: "primary",
                fontSize: 8,
                "&:before": {
                  ml:0
                }
              }
            }}>
              {data.infos.title && (
                <Heading as="h2" variant="h2" mb={6} mt="0 !important">{data.infos.title}</Heading>
              )}
              {data.infos.address && (
                <Flex mb={6}>
                  <Box mr={3}><Fontello icon="poi" /></Box>              
                  <Markdown>{data.infos.address}</Markdown>
                </Flex>
              )}
              {data.infos.phone && (
                <a href={telLink = telLink + data.infos.phone.replace(/[- )(]/g,'')}>
                  <Flex alignItems="center" mb={1}>
                    <Box mr={3}><Fontello icon="phone" /></Box>              
                    <Text>{data.infos.phone}</Text>
                  </Flex>
                </a>
              )}
              {data.infos.fax && (
                <Flex alignItems="center" mb={6}>
                  <Box mr={3}><Fontello icon="fax" /></Box>              
                  <Text>{data.infos.fax}</Text>
                </Flex>
              )}
              {data.infos.emails && (
                data.infos.emails.map((item, i) => (
                  <Box key={i}>
                    <a href={mailTo + item.email}>
                      <Flex alignItems="center" mb={1}>
                        <Box mr={3}><Fontello icon="mail" /></Box>              
                        <Text>{item.email}</Text>
                      </Flex>
                    </a>
                  </Box>
                ))
              )}
            </Box>
          )}
          <Box
          variant="gridItem"
          width={data.showInfo ? [1, 1, 3/5] : 1}>
            <Box sx={{ position: "relative" }} height="100%">
              <Map position={data.position} />
            </Box>
          </Box>
        </Flex>
    </Wrapper>
  );
};

InfosMap.propTypes = {
  data: PropTypes.shape({
    position: PropTypes.any,
    showInfo: PropTypes.any,
    infos: PropTypes.any
  })
};
export default InfosMap;

export const query = graphql`
  fragment InfosMapBlock on MarkdownRemarkFrontmatterBlockscollection {
    position
    showInfo
    infos {
      title
      address
      phone
      fax
      emails {
        email
      }
    }
  }
`;
