import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Header from "./Header";
import Markup from "./Markup";
import Features from "./Features";
import Gallery from "./Gallery";
import Carousel from "./Carousel";
import Numbers from "./Numbers";
import Form from "./Form";
import FormMap from "./FormMap";
import Separator from "./Separator";
import Banner from "./Banner";
import ImgMarkupTwoCol from "./ImgMarkupTwoCol";
import Services from "./Services";
import InfosMap from "./InfosMap";
import ContactList from "./ContactList";
import RelatedContent from "./RelatedContent";
import ServicesSlider from "./ServicesSlider";
import FilesList from "./FilesList";
import BlockVariant from "./BlockVariant";

const blocksComponents = {
  Header: Header,
  Markup: Markup,
  Features: Features,
  Gallery: Gallery,
  Carousel: Carousel,
  Numbers: Numbers,
  Form: Form,
  FormMap: FormMap,
  Separator: Separator,
  Banner: Banner,
  ImgMarkupTwoCol: ImgMarkupTwoCol,
  Services: Services,
  InfosMap: InfosMap,
  ContactList: ContactList,
  RelatedContent: RelatedContent,
  ServicesSlider: ServicesSlider,
  FilesList: FilesList
};

const Block = ({ block, lang }) => {
  const BlockComp = blocksComponents[block.type];
  const Variant = BlockVariant(block);
  if (Variant) {
    return <Variant data={block} lang={lang} />;
  }
  if (BlockComp) {
    return <BlockComp data={block} lang={lang} />;
  }
  return <> </>;
};

Block.propTypes = {
  block: PropTypes.shape({
    type: PropTypes.string
  })
};
export default ({ blocks, lang }) => {
  blocks = blocks || [];
  return blocks.map((blockItem, i) => <Block block={blockItem} key={i} lang={lang} />);
};

export const query = graphql`
  fragment Blocks on MarkdownRemarkFrontmatterBlockscollection {
    type
    blockSettings {
      showTitle
      blockname
      bg
      color
      containerFluid
      id
      maxContainer
      variant
      parallax
      fullHeight
      align
      padding {
        bottom
        top
      }
      bgImg {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1000, quality: 64) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    ...HeaderBlock
    ...MarkupBlock
    ...GalleryBlock
    ...CarouselBlock
    ...NumbersBlock
    ...FeaturesBlock
    ...FormBlock
    ...FormMapBlock
    ...SeparatorBlock
    ...BannerBlock
    ...ImgMarkupTwoColBlock
    ...ServicesBlock
    ...InfosMapBlock
    ...ContactListBlock
    ...RelatedContentBlock
    ...ServicesSliderBlock
    ...FilesListBlock
  }
`;
