import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import { Box, Heading, Button } from "rebass/styled-components";
import Link from "../../UI/Link";
import ServicesList from "./ServicesList";

const Services = ({ data, lang }) => {
  return (
    <Wrapper data={data}>
      {data.title && (
        <Heading as="h2" alignItems="center" mb={3}>
          {data.title}
        </Heading>
      )}
      <ServicesList lang={lang} />
    </Wrapper>
  );
};

Services.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.any
  })
};
export default Services;

export const query = graphql`
  fragment ServicesBlock on MarkdownRemarkFrontmatterBlockscollection {
    title
  }
`;
