import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Wrapper from "../Wrapper";
import Markdown from "../../UI/Markdown";
import CustomImage from "../../UI/CustomImage";
import Link from "../../UI/Link";
import Grid from "../../UI/Grid";
import { Box, Heading, Flex, Button } from "rebass/styled-components";
import loadable from "@loadable/component";
const Slider = loadable(() => import("../../UI/Slider"));

const TestimonialSlides = ({data}) => {
  data.items = data.items || [];
  var settings = {
    dots: true,
    autoplaySpeed: 8000,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    // adaptiveHeight: true
  };
  const sx = {
    ".slick-slide": {
      "blockquote": {
        border: "none !important",
        m: "0 !important",
        p: "0 !important"
      },
      "&.slick-current": {
        zIndex: 10
      },
      "& > div > div": {
        display: "block !important"
      }
    },
    ".slick-track": {
      display: "flex",
      alignItems: "center"
    },
    "ul.slick-dots": {
      mt: [0],
      position: ["inherit"],
      bottom: 0,
      left: "0",
      display: "flex !important",
      alignItems: "center",
      justifyContent: "center",
      height: "64px",
      "li": {
          p: "0 !important",
          ml: 0,
          mr: 2,
          height: "auto",
          width: "auto",
          "&:before": {
            display: "none"
          },
          "button": {
            p: 0,
            width: "8px",
            height: "8px",
            bg: "gray",
            borderRadius: "4px",
            transition: "all 200ms linear",
            "&:before": {display: "none"}
          },
          "&.slick-active": {
            "button": {
              transform: "scale(1.5)",
              bg: "primary"
            }
          }
      }
    }
  };
  return (
    <Wrapper data={data}>
      <Box  sx={sx}>
        <Slider {...settings}>
        {data.items.map((item, i) => (
            <Markdown display="flex !important" sx={{textAlign:"center"}} key={i}>{item.content}</Markdown> 
          )
        )}
        </Slider>
      </Box>
      </Wrapper>
  );
};

export default TestimonialSlides;
