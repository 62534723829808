import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Markdown from "../../UI/Markdown";
import Wrapper from "../Wrapper";
import { Box, Button } from "rebass/styled-components";
import Link from "../../UI/Link";

const Markup = ({ data }) => {
  return (
    <Wrapper data={data}>
      <Markdown>{data.content}</Markdown>
      {data.cta && (
        <Link href={data.cta.href}>
          <Button variant={data.cta.download ? "linkDownload" : "linkText"}>
            {data.cta.label}
          </Button>
        </Link>
      )}
    </Wrapper>
  );
};

Markup.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.any,
    title: PropTypes.any,
    cta: PropTypes.any,
  })
};
export default Markup;

export const query = graphql`
  fragment MarkupBlock on MarkdownRemarkFrontmatterBlockscollection {
    content
    cta {
      label
      href
      download
    }
  }
`;
