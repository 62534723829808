import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import Fontello from "../../UI/Fontello";
import { Box, Heading, Flex, Text } from "rebass/styled-components";

const ContactList = ({ data }) => {
  var telLink = "tel:+687";
  var phoneNumber = "";
  return (
    <Wrapper data={data} pt={[0,0,0]}>
      {data.group.map((pole, i) => (
        <Box
        key={i}
        sx={{
          "&:not(:first-child)": {
            mt:[8,10]
          }
        }}>
          {pole.title && (
            <Heading as="h2" variant="h2" mb={6}>{pole.title}</Heading>
          )}
          {pole.contact && (
            <Box>
              {pole.contact.map((item, i) => (
                telLink = "tel:+687",
                <Flex pb={[6,6,3]} key={i} variant="grid">
                  <Box variant="gridItem" pb={[2,2,0]} width={[1,1,1/2]}>
                    <Text fontWeight="bold" fontFamily="heading">{item.name}</Text>
                  </Box>
                  <Flex
                  variant="gridItem"
                  width={[1,1,1/2]}
                  sx={{
                    "i": {
                      color: "primary",
                      fontSize: 4,
                      "&:before": {
                        ml:0
                      }
                    }
                  }}>
                    {item.phone && (
                      phoneNumber = item.phone.replace('+687',''),
                      <Box width={1/2}>
                        <a href={telLink + phoneNumber.replace(/[- )(]+/g,'').replace(/\./g, '')}>
                          <Flex alignItems="center">
                            <Box mr={1}><Fontello icon="phone" /></Box>              
                            <Text>+687 <strong>{phoneNumber.replace(/\./g, '')}</strong></Text>
                          </Flex>
                        </a>                        
                      </Box>
                    )}
                    {item.cell && (
                      phoneNumber = item.cell.replace('+687',''),
                      <Box width={1/2}>
                        <a href={telLink + phoneNumber.replace(/[- )(]+/g,'').replace(/\./g, '')}>
                          <Flex alignItems="center">
                            <Box mr={1}><Fontello icon="cell" /></Box>              
                            <Text>+687 <strong>{phoneNumber.replace(/\./g, ' ')}</strong></Text>
                          </Flex>
                        </a>                        
                      </Box>
                    )}
                  </Flex>
                </Flex>
              ))}
            </Box>
          )}
        </Box>
      ))}
    </Wrapper>
  );
};

ContactList.propTypes = {
  data: PropTypes.shape({
    group: PropTypes.any
  })
};
export default ContactList;

export const query = graphql`
  fragment ContactListBlock on MarkdownRemarkFrontmatterBlockscollection {
    group {
      title
      contact {
        name
        phone
        cell
      }
    }
  }
`;
